<template>
  <b-modal
    id="create-order-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>Incluir verba</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="text-center mt-3">
      <!-- ORDER CREATION FORM -->
      <form @submit.prevent="submit" class="text-start default-form" v-if="!order">
        <div class="form-group mb-3">
          <label class="form-label fw-bold">
            Anunciante
            <a href="#" @click.prevent="openNewAdvertiserModal" v-if="!advertiser && company.type === 'AGENCY'">Novo anunciante</a>
          </label>
          <div v-if="advertisersLoader">
            <Loader
              :size="'small'"
              :align="'start'"
            />
          </div>
          <div class="custom-select" v-else>
            <select class="form-control" v-model.trim="$v.form.advertiser_id.$model">
              <option disabled="true" selected="true">Selecione</option>
              <template v-if="advertisers.length > 0">
                <option v-for="(lAdvertiser, index) in advertisers" :key="index" :value="lAdvertiser.id">{{ lAdvertiser.name }}</option>
              </template>
            </select>
          </div>
        </div>
        <div class="form-group mb-3">
          <label class="form-label fw-bold">Valor</label>
          <Money
            class="form-control mb-3"
            v-model.trim="form.amount"
            v-bind="money"
          />
          <p class="font-size-xSmall mt-2 text-center" v-if="form.amount < 500">Valor mínimo de: R$ 500,00</p>
          <p class="font-size-xSmall mt-2 text-center" v-if="form.amount > 50000">Valor máximo de: R$ 50.000,00</p>
          <p class="text-center font-size-xSmall" v-if="companySplitAmount > 0 && form.amount >= 500 &&  form.amount < 50000">
            Você receberá <strong>{{ companySplitAmount | formatMoney }}</strong> de bonificação. <span v-b-tooltip.hover :title="`A cada novo investimento, a Pixel Roads te presenteia com ${companySplitPercentage}% a mais do seu valor investido.`" class="font-size-small"><i class="fa fa-question-circle"></i></span>
          </p>
        </div>
        <div class="text-center mt-4">
          <Button
            type="submit"
            class="btn btn-primary"
            :loading="loader"
            :disabled="$v.$invalid"
          >
            Criar ordem de pagamento
          </Button>
        </div>
      </form> <!-- END ORDER CREATION FORM -->
      <!-- ORDER DETAILS -->
      <div v-else>
        <p><strong>Ordem de pagamento criada com sucesso!</strong></p>
        <p>
          <strong>Anunciante:</strong> {{ order.advertiser.name }}<br/>
          <strong>Valor:</strong> {{ (order.amount / 100) | formatMoney }}
        </p>
        <p>
         Para realizar o pagamento, <a :href="order.payment_url" class="link-block-color1" target="_blank">clique aqui</a>.
        </p>
        <p>
          Caso prefira, copie e cole o endereço do nosso parceiro PagarMe em seu navegador.
        </p>
        <p>
          <strong>{{ order.payment_url }}</strong>
        </p>
      </div> <!-- END ORDER DETAILS -->
    </div>
  </b-modal>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import OrdersService from '@/modules/orders/services/orders-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import { Money } from 'v-money'
import { between, required } from 'vuelidate/lib/validators'

export default {
  name: 'CreateOrderModal',
  props: ['advertiser', 'company', 'reloadAdvertisers'],
  components: {
    AlertWraper,
    Button,
    Loader,
    Money
  },
  data () {
    return {
      advertisers: [],
      advertisersLoader: false,
      content: null,
      content_type: 'error',
      order: null,
      form: {
        advertiser_id: '',
        amount: ''
      },
      loader: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      }
    }
  },
  validations () {
    return {
      form: {
        advertiser_id: { required },
        amount: {
          required,
          between: between(500, 50000)
        }
      }
    }
  },
  watch: {
    reloadAdvertisers (newValue, oldValue) {
      if (newValue) this.getAdvertisers()
    }
  },
  computed: {
    /**
     * Amount that will be split to the company
     */
    companySplitAmount () {
      if (!this.company.split || !this.form.amount) return 0
      return this.form.amount * (this.company.split / 100)
    },
    /**
     * Percentage of split
     */
    companySplitPercentage () {
      return this.company.split ? this.company.split : 0
    }
  },
  methods: {
    /**
     * Get advertisers
     */
    async getAdvertisers () {
      this.advertisersLoader = true
      try {
        const advertisers = await AdvertisersService.getAdvertisers({
          companyId: this.company.id,
          itemsPerPage: 100
        })
        if (this.advertiser) {
          this.advertisers = advertisers.data.filter(item => {
            return item.id === this.advertiser.id
          })
        } else {
          this.advertisers = advertisers.data
        }
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.advertisersLoader = false
    },
    /**
     * On modal hidden
     */
    hidden () {
      this.order = null
      this.form.advertiser_id = ''
      this.form.amount = ''
    },
    /**
     * Open new advertiser modal
     */
    openNewAdvertiserModal () {
      this.$bvModal.show('upsert-advertiser-modal')
    },
    /**
     * Set advertiser on load
     */
    async setAdvertiser () {
      if (this.advertisers.length === 0) await this.getAdvertisers()
      if (this.advertiser) this.form.advertiser_id = this.advertiser.id
    },
    /**
     * On modal shown
     */
    shown () {
      this.setAdvertiser()
    },
    /**
     * Submit
     */
    async submit () {
      this.loader = true
      try {
        const order = await OrdersService.createOrder(this.form)
        this.order = order
        this.$emit('order-created', this.order)
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
