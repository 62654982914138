import axios from '@/plugins/axios'

/**
 * Create order
 */
const createOrder = async ({
  // eslint-disable-next-line camelcase
  advertiser_id,
  amount
}) => {
  try {
    const form = {
      advertiser_id,
      amount: parseInt(amount * 100)
    }
    const response = await axios.post('orders', form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Create payment
*/
const createPayment = async (id, {
  address = null,
  // eslint-disable-next-line camelcase
  billing_profile = null,
  // eslint-disable-next-line camelcase
  card_hash = null,
  // eslint-disable-next-line camelcase
  payment_method
}) => {
  try {
    const form = {
      payment_method: payment_method
    }
    if (address) form.address = address
    // eslint-disable-next-line camelcase
    if (card_hash) form.card_hash = card_hash
    // eslint-disable-next-line camelcase
    if (billing_profile) form.billing_profile = billing_profile
    const response = await axios.post(`orders/${id}/payment`, form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Destroy order
 */
const destroyOrder = async id => {
  try {
    const response = await axios.post(`orders/${id}`, {
      _method: 'DELETE'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Download NFSe
 */
const downloadNFSe = async id => {
  try {
    const response = await axios.get(`orders/${id}/nfse/download`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get order
 */
const getOrder = async (id, isPayment = false) => {
  const params = {}
  if (isPayment) params.is_payment = 1
  try {
    const response = await axios.get(`orders/${id}`, {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get orders
 */
const getOrders = async ({
  advertiserId = null,
  // eslint-disable-next-line camelcase
  start_date = null,
  // eslint-disable-next-line camelcase
  end_date = null,
  page = 1,
  status = null
}) => {
  try {
    const params = {
      page: page
    }
    if (advertiserId) params.advertiser_id = advertiserId
    // eslint-disable-next-line camelcase
    if (start_date) params.start_date = start_date
    // eslint-disable-next-line camelcase
    if (end_date) params.end_date = end_date
    if (status) params.status = status
    const response = await axios.get('orders', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Issue NFSe
 */
const issueNFSe = async id => {
  try {
    const response = await axios.post(`orders/${id}/nfse/issue`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Replicate of order
 */
const replicateOrder = async (id) => {
  try {
    const response = await axios.post(`orders/${id}/replicate`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  createOrder,
  createPayment,
  destroyOrder,
  downloadNFSe,
  getOrder,
  getOrders,
  issueNFSe,
  replicateOrder
}
