<template>
  <div class="d-inline-block">
    <div id="tooltip-recipient">
      <Button
        class="btn btn-color1"
        :class="parsedBtnClasses"
        :disabled="!isNewOrderAvailable"
        @click="openNewOrderModal"
      >
        Incluir verba
      </Button>
      <template v-if="!isNewOrderAvailable">
        <b-tooltip custom-class="font-size-xSmall" target="tooltip-recipient" triggers="hover">
          <p class="mb-0">{{ newOrderTooltipText }}</p>
        </b-tooltip>
      </template>
      <CreateOrderModal
        :company="user.company"
        :advertiser="advertiser"
        :reloadAdvertisers="reloadAdvertisers"
        v-if="user"
        @order-created="handleOrderCreated"
      />
    </div>
  </div>
</template>

<script>

import UsersService from '@/modules/users/services/users-service'
import { mapState } from 'vuex'

import Button from '@/components/common/Button/Button'
import CreateOrderModal from '@/components/orders/CreateOrderModal'

export default {
  name: 'CreateOrderButton',
  props: ['advertiser', 'reloadAdvertisers', 'btnClasses'],
  components: {
    Button,
    CreateOrderModal
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    /**
     * Return if company is advertiser
     */
    isAdvertiser () {
      return this.user && this.user.company.type === 'ADVERTISER'
    },
    /**
     * Return if new order button should be available
     */
    isNewOrderAvailable () {
      if (!this.user) return false
      if (this.isAdvertiser) return true
      if (!this.user.company.recipient_status && (this.user.role.slug !== 'user-manager' && this.user.role.slug !== 'administrator')) return false
      return true
    },
    /**
     * Text for the tooltip when new order button is disabled
     */
    newOrderTooltipText () {
      if (!this.user) return ''
      if (!this.user.company.recipient_status && (this.user.role.slug !== 'user-manager' && this.user.role.slug !== 'administrator')) return 'É necessário criar a conta de recebedor da agência antes de incluir verba. Peça para um usuário administrador realizar a operação.'
      return ''
    },
    parsedBtnClasses () {
      return this.btnClasses ? this.btnClasses : ''
    }
  },
  methods: {
    /**
     * Handle order created
     */
    handleOrderCreated (order) {
      this.$emit('order-created', order)
    },
    /**
     * Handle recipient created
     */
    async handleRecipientCreated () {
      await UsersService.me()
      this.$emit('recipient-created')
    },
    /**
     * Open new order modal
     */
    openNewOrderModal () {
      if (!this.user) return

      this.$bvModal.show('create-order-modal')
    }
  }
}
</script>
